<template>
  <main class="main" role="main">
    <div class="page bg">
      <div class="inner">
        <div class="page_con">
          <div class="party_list_wrap">
            <div class="list_top">
              <div class="left">
                <button class="total">
                  결산 상세 내역
                  <span>{{ $dayjs(dt).format('(YYYY.MM.DD)') }}</span>
                </button>
              </div>
              <div class="right"></div>
            </div>
            <div class="cash_point_table">
              <div class="cash_point_table_paper" v-if="buttonIndex === 0">
                <dl>
                  <dd class="cash_point_table_row">
                    충전 캐시
                    <span class="plus">{{
                      $Util.formatNumber2(summaryDetail.charge, { unit: '' })
                    }}</span>
                  </dd>
                  <dd class="cash_point_table_row">
                    판매 적립
                    <span class="plus">{{
                      $Util.formatNumber2(summaryDetail.gain, { unit: '' })
                    }}</span>
                  </dd>
                  <dd class="cash_point_table_row">
                    환불 캐시
                    <span class="plus">{{
                      $Util.formatNumber2(summaryDetail.refund, { unit: '' })
                    }}</span>
                  </dd>
                  <dd class="cash_point_table_row mt">
                    충전 취소
                    <span class="minus">{{
                      $Util.formatNumber2(summaryDetail.chargeCancel, {
                        unit: ''
                      })
                    }}</span>
                  </dd>
                  <dd class="cash_point_table_row">
                    사용 캐시
                    <span class="minus">{{
                      $Util.formatNumber2(summaryDetail.using, { unit: '' })
                    }}</span>
                  </dd>
                  <dd class="cash_point_table_row">
                    출금 캐시
                    <span class="minus">{{
                      $Util.formatNumber2(summaryDetail.withdraw, { unit: '' })
                    }}</span>
                  </dd>
                  <dd class="cash_point_table_row">
                    출금 수수료
                    <span class="minus">{{
                      $Util.formatNumber2(summaryDetail.withdrawFee, {
                        unit: ''
                      })
                    }}</span>
                  </dd>
                  <dt class="cash_point_table_row mt total">
                    총
                    <span>{{ $Util.formatNumber2(total, { unit: '' }) }}</span>
                  </dt>
                </dl>
              </div>
              <div class="cash_point_table_paper" v-if="buttonIndex === 1">
                <dl>
                  <dd class="cash_point_table_row">
                    적립 포인트
                    <span class="plus">{{
                      $Util.formatNumber2(summaryDetail.gain, { unit: '' })
                    }}</span>
                  </dd>
                  <dd class="cash_point_table_row mt">
                    사용 포인트
                    <span class="minus">{{
                      $Util.formatNumber2(summaryDetail.using, { unit: '' })
                    }}</span>
                  </dd>
                  <dt class="cash_point_table_row mt total">
                    총
                    <span>{{ $Util.formatNumber2(total, { unit: '' }) }}</span>
                  </dt>
                </dl>
              </div>
            </div>
          </div>
          <cash-point-list
            :buttonIndex="buttonIndex"
            :list="list"
            :totalCnt="totalCnt"
            v-if="!$Util.isEmpty(list)"
          />
          <div class="empty_wrap" v-else>
            <div class="empty">
              <div>
                검색된 결과가 없습니다.
              </div>
            </div>
          </div>
          <observe-visible
            :key="initCnt"
            :page="page"
            :limit="limit"
            :totalCnt="totalCnt"
            @fetch="fnListFunction"
          />
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { componentState } from './index.js'
import CashPointList from '@/components/views/my-page/cash-point/list/index.vue'
export default {
  name: 'my-page-adjustment-detail-index',
  components: { CashPointList },
  setup() {
    return { ...componentState() }
  }
}
</script>
