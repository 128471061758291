import { computed, reactive, toRefs, getCurrentInstance } from 'vue'
import { useRouter } from 'vue-router'

export const componentState = props => {
  const { proxy } = getCurrentInstance()
  const router = useRouter()
  const state = reactive({
    buttonIndex: computed(() => props.buttonIndex),
    showList: computed(() =>
      props.list.map(e => ({
        ...e,
        title: e.title ? e.title : e.type === 'CHARGE' ? '캐시충전 진행 건' : ''
      }))
    ),
    totalCnt: computed(() => props.totalCnt),
    categoryNames: proxy.$_.keyBy(proxy.$ConstCode.CATEGORY2_VALUE, 'id'),
    cashTypes: proxy.$_.keyBy(proxy.$ConstCode.CASH_TYPE, 'value'),
    cashTypeGroup: proxy.$_.groupBy(proxy.$ConstCode.CASH_TYPE, 'isPlus')
  })
  const fnTagStatusName = item => {
    if (state.buttonIndex === 0) {
      return state.cashTypes[item.type].name
    }
    return item.pointType
  }
  const fnPartyView = async item => {
    if (!proxy.$Util.isEmpty(item.type)) {
      const creates = ['SELL', 'WITHDRAW']
      const joins = ['BUY', 'REFUND']
      const charge = ['CHARGE', 'CHARGE_CANCEL']
      let name, query
      if (joins.includes(item.type)) {
        if (item.productType === 'PARTY') {
          name = 'my-page-joined-party-view-index'
          query = { partyNo: item.productNo }
        } else {
          name = 'my-page-market-buyer-view-index'
          query = { marketNo: item.productNo, memberSeq: item.memberSeq }
        }
      } else if (creates.includes(item.type)) {
        if (item.productType === 'PARTY') {
          name = 'my-page-created-party-view-index'
          query = { partyNo: item.productNo }
        } else if (item.productType === 'MARKET') {
          name = 'my-page-market-view-index'
          query = { marketNo: item.productNo }
        }
      } else if (charge.includes(item.type)) {
        name = 'my-page-cash-charge-report-index'
        query = { poid: item.poid, cancel: item.type === 'CHARGE_CANCEL' }
      }
      if (!proxy.$Util.isEmpty(name) && !proxy.$Util.isEmpty(query)) {
        await router.push({ name, query })
      }
    }
  }
  const fnIsPlusCash = item => {
    if (state.buttonIndex === 0) {
      const temp = proxy.$_.find(state.cashTypeGroup[true], {
        value: item.type
      })
      return proxy.$Util.isEmpty(temp)
    }
    return item.pointType === '사용'
  }
  const fnGetNumber = item => {
    let result = ''
    if (state.buttonIndex === 0) {
      result = proxy.$Util.formatNumber2(item.cashAmt, {
        unit: ''
      })
      if (
        !proxy.$Util.isEmpty(
          proxy.$_.find(state.cashTypeGroup[false], {
            value: item.type
          })
        )
      ) {
        result = `-${result}`
      }
      return result
    } else {
      return proxy.$Util.formatNumber2(item.point, {
        unit: ''
      })
    }
  }
  return {
    ...toRefs(state),
    fnTagStatusName,
    fnPartyView,
    fnIsPlusCash,
    fnGetNumber
  }
}
