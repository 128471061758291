import { computed, reactive, toRefs, getCurrentInstance } from 'vue'
import { useRoute, useRouter } from 'vue-router'

const INIT_PAGE = 0
const INIT_LIMIT = 6
const INIT_TOTAL_CNT = 999

export const componentState = () => {
  const { proxy } = getCurrentInstance()
  const route = useRoute()
  const router = useRouter()
  const getButtonIndex = { cash: 0, point: 1 }
  const state = reactive({
    page: INIT_PAGE,
    limit: INIT_LIMIT,
    totalCnt: INIT_TOTAL_CNT,
    list: [],
    initCnt: 0,
    searchWord: '',
    paramsCash: computed(() => ({
      page: state.page + 1,
      searchDate: state.dt
    })),
    paramsPoint: computed(() => ({
      startNum: state.list.length + 1,
      endNum: (state.page + 1) * state.limit,
      searchDate: state.dt
    })),
    paramsCashSummaryDetail: computed({
      get: () => ({
        dt: state.dt
      })
    }),
    paramsPointSummaryDetail: computed({
      get: () => ({
        dt: state.dt
      })
    }),
    buttonIndex: getButtonIndex[route.params.name],
    dt: route.params.dt,
    summaryDetail: {
      charge: 0,
      gain: 0,
      using: 0,
      withdraw: 0,
      withdrawFee: 0,
      refund: 0
    },
    total: computed(() => {
      if (state.buttonIndex === 0) {
        const addCash =
          state.summaryDetail.charge +
          state.summaryDetail.gain +
          state.summaryDetail.refund
        const useCash =
          state.summaryDetail.using +
          state.summaryDetail.withdraw +
          state.summaryDetail.withdrawFee
        return addCash - useCash
      } else {
        return state.summaryDetail.gain - state.summaryDetail.using
      }
    })
  })

  const fnListFunction = async () => {
    let res = {}
    if (state.buttonIndex === 0) {
      res = await proxy.$MyPageSvc.postMyWizzCashCashHistory(state.paramsCash)
    } else {
      res = await proxy.$MyPageSvc.postMyWizzPointHistory(state.paramsPoint)
    }
    if (res.resultCode === '0000') {
      state.page++
      state.totalCnt = res.totalCnt
      state.list = state.list.concat(res.list)
    }
  }

  const getCashAndPointSummaryDetail = async () => {
    let res = {}
    if (state.buttonIndex === 0) {
      res = await proxy.$MyPageSvc.postMyWizzCashCashSummaryDetail(
        state.paramsCashSummaryDetail
      )
    } else {
      res = await proxy.$MyPageSvc.postMyWizzPointPointSummaryDetail(
        state.paramsPointSummaryDetail
      )
    }
    if (res.resultCode === '0000') {
      const summaryDetail = { ...res }
      delete summaryDetail.resultCode
      delete summaryDetail.resultMsg
      state.summaryDetail = { ...summaryDetail }
    }
  }

  const fnCashWithdraw = async () => {
    await router.push({ path: '/my-page/cash-point/withdraw' })
  }

  //init
  getCashAndPointSummaryDetail()

  return {
    ...toRefs(state),
    fnListFunction,
    fnCashWithdraw
  }
}
