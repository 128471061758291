<template>
  <div class="party_list_wrap">
    <div class="list_top">
      <div class="left">
        <button class="total">총 {{ totalCnt }}건</button>
      </div>
      <div class="right">
        <div class="hint">
          * 개별 판매적립 금액의 소수점 단위는 숨김 처리
        </div>
      </div>
    </div>
    <div class="party_list row_type">
      <!--'가로형: row_type'-->
      <ul class="party_list_ul">
        <li
          class="party_list_li"
          v-for="(item, index) in showList"
          :key="index"
          @click="fnPartyView(item)"
        >
          <div class="party_tag">
            <div class="left">
              <div
                class="pt_tag logo"
                style="--pt-txt-color: #fff;"
                :style="{
                  '--pt-bg-color': $ConstCode.getCategoryById(item.categoryId)
                    .color
                }"
                v-if="!$Util.isEmpty(item.categoryId)"
              >
                {{ categoryNames[item.categoryId].text }}
              </div>
              <div
                v-else
                class="pt_tag logo"
                style="--pt-txt-color: #fff; --pt-bg-color: #00ADEF"
              >
                {{ '위즈니' }}
              </div>
              <div class="pt_tag normal">
                {{ fnTagStatusName(item) }}
              </div>
            </div>
            <div class="right"></div>
          </div>
          <div class="party_ttl">{{ item.title }}</div>
          <div class="party_con">
            <div class="left">
              <div class="party_info">
                <dl>
                  <dt>
                    {{ $dayjs(item.regDt).format('YYYY.MM.DD HH:mm') }}
                  </dt>
                </dl>
              </div>
            </div>
            <div class="right">
              <div class="party_price">
                <span
                  class="value"
                  :class="{
                    minus: fnIsPlusCash(item)
                  }"
                  >{{ fnGetNumber(item) }}</span
                >
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { componentState } from './index.js'
export default {
  name: 'component-my-page-cash-point-list-index',
  props: {
    buttonIndex: {
      type: [Number],
      default: 0
    },
    list: {
      type: [Array],
      default: () => []
    },
    totalCnt: {
      type: [Number],
      default: 0
    }
  },
  setup(props) {
    return { ...componentState(props) }
  }
}
</script>

<style scoped></style>
